const TextArea = ({ name, label, data, updateData, disabled = false }) => {
    const text = (label === undefined ? name : label);
    return (
        <div className="col-span-full m-5 grid grid-cols-1 gap-x-6 gap-y-8">
            <div className="">
                <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900 text-left">{text.charAt(0).toUpperCase() + text.slice(1)}: </label>
                <div className="mt-2">
                    <textarea value={data || ''} onChange={(event) => {
                        updateData(event.target.value);
                    }} id={name} name={name} disabled={disabled} rows="11" className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                </div>
            </div>
        </div>
    );
}

export default TextArea;