import { useContext, useState } from "react";
import SaveButton from "../../components/SaveButton";
import TextArea from "../../components/TextArea";
import axios from "axios";
import AuthContext from "../../providers/AuthContext";
import Swal from "sweetalert2";

const Import = () => {
    const [importArea, setImportArea] = useState('');
    const [importedData, setImportedData] = useState('');
    const { cookies } = useContext(AuthContext);
    const importData = async e => {
        e.preventDefault();
        if (importArea) {
            axios.post(process.env.REACT_APP_API_URL + '/calendar/import', importArea, {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            }).then(response => {
                setImportArea('');
                setImportedData(JSON.stringify(response.data, null, 4));
            }).catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: e.response.data.error,
                });
                console.log(e);
            });
        }
    }

    return (
        <section className="items-center grid md:grid-cols-5" style={{ height: '100vh' }}>
            <div></div>
            <form onSubmit={importData} className="bg-white rounded-md p-12 col-span-3">
                <h2 className="text-black">
                    <strong>
                        {'Zaimportuj eventy (JSON)'}
                    </strong>
                </h2>
                <TextArea data={importArea} updateData={setImportArea} name='name' label='Eventy' />
                <h2 className="text-black">
                    <strong>
                        {'Zaimportowane eventy'}
                    </strong>
                </h2>
                <TextArea data={importedData} updateData={setImportedData} name='name' label='Zaimportowane' disabled={true} />
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                </div>
            </form>
        </section>
    );
}

export default Import;