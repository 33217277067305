import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import SaveButton from "../../components/SaveButton";
import BackButton from "../../components/BackButton";
import FaSelect from "../../components/FaSelect";

const TagForm = () => {
    const { id } = useParams();
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const isNewRecord = !(id > 0);
    const navigate = useNavigate();
    const [name, setName] = useState('');

    const getTag = useCallback(async () => {
        axios.get(API_URL + '/tag/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token
            }
        }).then(response => {
            const body = response.data;
            setName(body.name);
        })
            .catch(e => {
                console.log(e);
            })
    }, [API_URL, cookies.access_token, id]);

    const saveTag = async e => {
        e.preventDefault();
        if (isNewRecord) {
            axios.post(API_URL + '/tag', JSON.stringify({
                name: name,
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 201 || response.status === 200) {
                        navigate('/tags');
                    }
                })
                .catch(e => {
                    console.log(e);
                    //   setErrMsg(e.response.data.error);
                });
        } else {
            axios.put(API_URL + '/category/' + id, JSON.stringify({
                name: name,
            }), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 201 || response.status === 200) {
                        navigate('/tags');
                    }
                })
                .catch(e => {
                    console.log(e);
                    alert(e);
                    //   setErrMsg(e.response.data.error);
                });
        }
    }

    useEffect(() => {
        if (!isNewRecord) {
            getTag();
        }
    }, [isNewRecord, getTag]);

    return (
        <section className="items-center grid md:grid-cols-5" style={{ height: '100vh' }}>
            <div></div>
            <form onSubmit={saveTag} className="bg-white rounded-md p-12 col-span-3">
                <h2 className="text-black">
                    <strong>
                        {isNewRecord ? 'Nowy tag' : 'Aktualizacja tagu nr ' + id}
                    </strong>
                </h2>
                <Input data={name} updateData={setName} name='name' label='Nazwa' />
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                    <BackButton returnPath='/tags' />
                </div>
            </form>
        </section>
    );
}

export default TagForm;