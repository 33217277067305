import { Autocomplete, FormControl, TextField } from "@mui/material"
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../providers/AuthContext";

const MultiSelect = ({ name, label, isRequired = false, data, updateData, endpoint }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const text = label === undefined ? name : label;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const { cookies } = useContext(AuthContext);
    const loading = open && options.length === 0;

    useEffect(() => {
        axios.get(API_URL + endpoint, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token,
                "Content-Type": 'application/json'
            }
        }).then(response => {
            setOptions(response.data);
        }).catch(err => {
            console.log(err);
        }).finally(() => {

        });
    }, [API_URL, endpoint, cookies.access_token]);

    useEffect(() => {
        setSelectedValue(options.filter(obj => data?.some(d => d.id === obj.id)) || []);
    }, [options, data, name]);

    const handleOnChange = (event, value, reason) => {
        console.log(value);
        setSelectedValue(value);
        updateData(value);
    };


    return (
        <div className="m-5 grid grid-cols-1 gap-x-6 gap-y-8">
            <FormControl fullWidth>
                <label
                    htmlFor={name}
                    className="block text-sm font-medium leading-6 text-gray-900 text-left"
                >
                    {text.charAt(0).toUpperCase() + text.slice(1)}:
                </label>
                <div className="mt-2">
                    <Autocomplete
                        size="small"
                        includeInputInList
                        disableClearable
                        open={open}
                        multiple={true}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        onChange={handleOnChange}
                        loading={loading}
                        options={options}
                        id={name}
                        name={name}
                        value={selectedValue}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        sx={{
                            height: '38px',
                        }}
                        renderInput={(params) => (
                            <TextField
                                required={isRequired}
                                {...params}
                                sx={{ height: '38px', borderRadius: 0 }}
                            />
                        )}
                    />
                </div>
            </FormControl>
        </div>
    );
};

export default MultiSelect;