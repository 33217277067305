import { useNavigate, useParams } from "react-router-dom";
import Input from "../../components/Input";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../providers/AuthContext";
import Select from "../../components/Select";
import TextArea from "../../components/TextArea";
import CheckboxInput from "../../components/CheckboxInput";
import SaveButton from "../../components/SaveButton";
import BackButton from "../../components/BackButton";
import MultiSelect from "../../components/MultiSelect";

const ArticleForm = () => {
    const { id } = useParams();
    const { cookies } = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const isNewRecord = !(id > 0);
    const navigate = useNavigate();
    const [category, setCategory] = useState({});
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [tags, setTags] = useState([]);

    const getArticle = useCallback(async () => {
        axios.get(API_URL + '/article/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.access_token
            }
        }).then(response => {
            const body = response.data;
            setCategory(body.category);
            setTitle(body.title);
            setText(body.text);
            setIsVisible(body.visible);
            setTags(body.tags);
        })
            .catch(e => {
                console.log(e);
            });
    }, [API_URL, cookies.access_token, id]);

    const saveArticle = async e => {
        e.preventDefault();
        const data = {
            title: title,
            text: text,
            categoryId: category?.id,
            visible: isVisible,
            tags: tags,
        };
        if (isNewRecord) {
            axios.post(API_URL + '/article', JSON.stringify(data), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 201 || response.status === 200) {
                        navigate('/');
                    }
                })
                .catch(e => {
                    console.log(e);
                    alert(e);
                    //   setErrMsg(e.response.data.error);
                });
        } else {
            axios.put(API_URL + '/article/' + id, JSON.stringify(data), {
                headers: {
                    Authorization: 'Bearer ' + cookies.access_token,
                    "Content-Type": 'application/json'
                }
            })
                .then(response => {
                    console.log(response);
                    if (response.status === 201 || response.status === 200) {
                        navigate('/');
                    }
                })
                .catch(e => {
                    console.log(e);
                    alert(e.request.responseText);
                    //   setErrMsg(e.response.data.error);
                });
        }
    }

    useEffect(() => {
        if (!isNewRecord) {
            getArticle();
        }
    }, [isNewRecord, getArticle]);

    return (
        <section className="items-center grid md:grid-cols-5" style={{ height: '100vh' }}>
            <div></div>
            <form onSubmit={saveArticle} className="bg-white rounded-md p-12 col-span-3">
                <h2 className="text-black">
                    <strong>
                        {isNewRecord ? 'Nowy artykuł' : 'Aktualizacja artykułu nr ' + id}
                    </strong>
                </h2>
                <Input data={title} updateData={setTitle} name='title' label='tytuł' />
                <Select data={category} updateData={setCategory} name='categoryId' label='Kategoria' endpoint='/category/all' />
                <TextArea data={text} updateData={setText} name='text' label='Treść' />
                <MultiSelect data={tags} updateData={setTags} name='tags' label='Tagi' endpoint='/tag/all' />
                <CheckboxInput data={isVisible} updateData={setIsVisible} name='visible' label='Czy widoczny?' />
                <div className="py-5 flex flex-row col-gap-4">
                    <SaveButton />
                    <BackButton returnPath='/' />
                </div>
            </form>
        </section>
    );
}

export default ArticleForm;